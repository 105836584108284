import React, { Fragment } from "react";

const HeaderWrapper = ({ title, name, banner }) => {
  return (
    <div class="hero-container">
      <img class="hero-image" src={banner} alt="Hero Image" />
      {/* <div class="hero-text">
        <h1>Welcome to Our Website</h1>
        <p>Discover amazing content and services.</p>
        <button>Learn More</button>
      </div> */}
    </div>
  );
};

export default HeaderWrapper;
