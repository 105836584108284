import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import Footer from "@/components/footer";
import ServiceInfo from "@/components/service-info";
import SponsorOne from "@/components/sponsor-one";
import ProductList from "@/components/ProductsList";
import ServiceBanner from "@/images/DDImages/1.png";
import HeaderWrapper from "@/components/header-wrapper";

const Service = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Service Page">
        <HeaderOne />
        {/* <PageHeader title="Service" name="Service" /> */}
        <HeaderWrapper
          title="Services"
          name="Services"
          banner={ServiceBanner}
        />

        {/* <ServiceOne /> */}
        <ProductList />
        <ServiceInfo />
        {/* <BenefitOne /> */}
        <SponsorOne />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Service;
